import React from "react";
import Layout from "../components/Layout";
import Button from "../components/Buttons";
import Footer from "../components/Footer";
import { AniNotification, AniBubbles } from "../components/AnimatedVisual";
import tempHeader from "../assets/images/tempheader_2.png";

// Import static data from data file, can be replaced by API
import { dataNotifications, dataBubbles } from "../data";

import "../styles/pages/index.scss";

class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location} pageName="Home" titleTemplate>
        <header>
          <img src={tempHeader} alt="Header Visual Rodeo" />
        </header>
        <div className="content--wrapper">
          <section className="col col--visual_1">
            <AniNotification data={dataNotifications} />
          </section>
          <section className="intro">
            <h1>Rodeo</h1>
            <p>Jump in and out of conversations with friends</p>
          </section>
          <section className="cta">
            <h2>Join Rodeo now!</h2>
            <Button
              to="https://apps.apple.com/us/app/id1516644054"
              external
              inverted="false"
              text="Try now"
            />
          </section>
          <Footer />
          <section className="col col--visual_2">
            <AniBubbles data={dataBubbles} dataNoti={dataNotifications} />
          </section>
        </div>
      </Layout>
    );
  }
}

export default RootIndex;

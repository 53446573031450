import icon0 from "./assets/images/profile/icon-0.png";
import icon1 from "./assets/images/profile/icon-1.png";
import icon2 from "./assets/images/profile/icon-2.png";
import icon3 from "./assets/images/profile/icon-3.png";
import icon4 from "./assets/images/profile/icon-4.png";
import icon5 from "./assets/images/profile/icon-5.png";

// set global names
export let dataPersons = [
  { name: "Sara", icon: icon0, el: 0 },
  { name: "Rhyzzle", icon: icon1, el: 1 },
  { name: "Stella", icon: icon2, el: 2 },
  { name: "Evita", icon: icon3, el: 3 },
  { name: "Russty", icon: icon4, el: 4 },
  { name: "Andy", icon: icon5, el: 5 },
];

// random number from array generator
function* shuffle(array) {
  var i = array.length;

  while (i--) {
    yield array.splice(Math.floor(Math.random() * (i + 1)), 1)[0];
  }
}

let ranNums = shuffle([0, 1, 2, 3, 4, 5]);

let chosenNumber1 = ranNums.next().value;
let chosenNumber2 = ranNums.next().value;

export let chosenName1 = dataPersons[chosenNumber1].name;
export let chosenName2 = dataPersons[chosenNumber2].name;

// set data for notifications
export let dataNotifications = {
  noti0: {
    title: `${dataPersons[0].name} is free to talk`,
    el: 0,
  },
  noti1: {
    title: `${dataPersons[1].name} and ${dataPersons[0].name} are talking 🤠`,
    el: 1,
  },
  noti2: {
    title: `${dataPersons[2].name}, ${dataPersons[1].name} and ${dataPersons[0].name} are talking 🤠`,
    el: 2,
  },
  noti3: {
    title: `${dataPersons[3].name} joined`,
    el: 3,
  },
  noti4: {
    title: `${dataPersons[4].name} joined`,
    el: 4,
  },
  noti5: {
    title: `${dataPersons[5].name} joined`,
    el: 5,
  },
  notiLoop1: {
    title: `${chosenName1} is talking`,
    el: dataPersons[chosenNumber1].el,
    name: chosenName1,
  },
  notiLoop2: {
    title: `${chosenName2} is talking`,
    el: dataPersons[chosenNumber2].el,
    name: chosenName2,
  },
};

// set data for bubbles
export let dataBubbles = {
  bub0: {
    name: `${dataPersons[0].name}`,
    img: dataPersons[0].icon,
    color: "#F086FF",
    icon: "💰",
    el: 0,
  },
  bub1: {
    name: `${dataPersons[1].name}`,
    img: dataPersons[1].icon,
    color: "#6DF540",
    icon: "muted",
    el: 1,
  },
  bub2: {
    name: `${dataPersons[2].name}`,
    img: dataPersons[2].icon,
    color: "#FE7913",
    icon: " ",
    el: 2,
  },
  bub3: {
    name: `${dataPersons[3].name}`,
    img: dataPersons[3].icon,
    color: "#9DFFC5",
    icon: "🤩",
    el: 3,
  },
  bub4: {
    name: `${dataPersons[4].name}`,
    img: dataPersons[4].icon,
    color: "#6B4FF5",
    icon: "🔥",
    el: 4,
  },
  bub5: {
    name: `${dataPersons[5].name}`,
    img: dataPersons[5].icon,
    color: "#F10000",
    icon: "👀",
    el: 5,
  },
};

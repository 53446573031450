import React from "react";
import logoSmall from "../../../assets/images/icon-notif.png";

import "../styles.scss";

const Notification = ({ title, desc, time, el }) => {
  return (
    <a
      className="notif--el"
      data-order={el}
      href="https://midaskwant734769.typeform.com/to/Iz1YdGFG"
      target="_blank"
    >
      <div className="top">
        <div className="app">
          <div className="app-icon">
            <img src={logoSmall} alt="Icon Rodeo Small" />
          </div>
          <h4 className="app-name">Rodeo</h4>
        </div>
        <span className="time">
          {el} {time || "now"}
        </span>
      </div>
      <div className="bottom">
        <h3 className="title">{title}</h3>
        <p className="desc">{desc || "Tap here to join"}</p>
      </div>
    </a>
  );
};

export default Notification;

import React from "react";
import { Link } from "gatsby";
import "./styles.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <p className="small">
        Rodeo is a product from{" "}
        <a href="mailto:midas@alumni.stanford.edu">
          Golden Voice Technologies Inc.
        </a>
      </p>
      <p className="small">
        Backed by
        <a href="https://floodgate.com/"> Floodgate</a>,
        <a href="https://www.boxgroup.com/"> BoxGroup</a>,
        <a href="https://www.svangel.com/"> SVAngel</a> and our friends &
        family.
      </p>
      <p className="small">
        <a href="http://goldenvoicetechnologies.com/Golden-Voice-Technologies-Privacy-Policy.pdf">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a href="https://goldenvoicetechnologies.com/Golden-Voice-Tech-Terms-of-Service.pdf">Terms of Service</a>
      </p>
      <p className="small">
        From Palo Alto, Calif.
        <span role="img" aria-label="heart and cowboy emoji">
          ❤️🤠
        </span>
      </p>
    </footer>
  );
};

export default Footer;

import React, { useEffect } from "react";
import Notification from "./Notification/index";
import Bubble from "./Bubble/index";
import { motion, useAnimation } from "framer-motion";

import "./styles.scss";

// set animation variables
let startDelay = 2.4;
let baseDur = 0.2;
let heightNoti = 100;
let addedDelay = 2.6;

export const AniNotification = ({ data }) => {
  // All cards have the same animation, looping over the numbers to control each card
  const otherCards = async (order) => {
    // Convert string to function while having dynamic paramenter, everyone hates eval, but should work here
    let aniControl = eval(`controls${order}`);

    // first step: appear
    await aniControl.start({
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        delay: startDelay + addedDelay * order, // should be 4.2
        duration: baseDur,
      },
    });
    // second step: slide and scale down
    await aniControl.start({
      y: [0, heightNoti],
      scale: [1, 0.8],
      transition: {
        delay: startDelay,
        duration: baseDur,
      },
    });
    // third step: diseapear
    return await aniControl.start({
      opacity: [1, 0],
      scale: [0.8, 0],
      transition: {
        delay: startDelay,
        duration: baseDur,
      },
    });
  };

  const secondLastCard = async (order) => {
    // Convert string to function while having dynamic paramenter, everyone hates eval, but should work here
    let aniControl = eval(`controls${order}`);

    // first step: appear
    await aniControl.start({
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        delay: startDelay + addedDelay * order, // should be 4.2
        duration: baseDur,
      },
    });
    // second step: slide and scale down
    return await aniControl.start({
      y: [0, heightNoti],
      scale: [1, 0.8],
      transition: {
        delay: startDelay,
        duration: baseDur,
      },
    });
  };
  const lastCard = async (order) => {
    // Convert string to function while having dynamic paramenter, everyone hates eval, but should work here
    let aniControl = eval(`controls${order}`);

    // first step: appear
    return await aniControl.start({
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        delay: startDelay + addedDelay * order, // should be 4.2
        duration: baseDur,
      },
    });
  };

  // declare animation sequences
  const controls0 = useAnimation();
  const controls1 = useAnimation();
  const controls2 = useAnimation();
  const controls3 = useAnimation();
  const controls4 = useAnimation();
  const controls5 = useAnimation();
  const controls6 = useAnimation();
  const controls7 = useAnimation();
  const controls8 = useAnimation();

  // Set fixed sequence for animations to play
  const sequenceAnimation = async () => {
    otherCards(0);
    otherCards(1);
    otherCards(2);
    otherCards(3);
    otherCards(4);
    otherCards(5);
  };

  const lastSequenceAnimation = async (data) => {
    secondLastCard(6);
    lastCard(7);
  };
  const totalLoop = async () => {
    await sequenceAnimation();
    await lastSequenceAnimation();
  };

  totalLoop();

  return (
    <div className="animation--wrapper__notification">
      <div className="notifications">
        <motion.div animate={controls0} className="notif--wrapper">
          <Notification {...data.noti0} />
        </motion.div>
        <motion.div animate={controls1} className="notif--wrapper">
          <Notification {...data.noti1} />
        </motion.div>
        <motion.div animate={controls2} className="notif--wrapper">
          <Notification {...data.noti2} />
        </motion.div>
        <motion.div animate={controls3} className="notif--wrapper">
          <Notification {...data.noti3} />
        </motion.div>
        <motion.div animate={controls4} className="notif--wrapper">
          <Notification {...data.noti4} />
        </motion.div>
        <motion.div animate={controls5} className="notif--wrapper">
          <Notification {...data.noti5} />
        </motion.div>
        <motion.div animate={controls6} className="notif--wrapper">
          <Notification {...data.notiLoop1} />
        </motion.div>
        <motion.div animate={controls7} className="notif--wrapper">
          <Notification {...data.notiLoop2} />
        </motion.div>
        <motion.div animate={controls8} className="notif--wrapper">
          <Notification {...data.notiLoop3} />
        </motion.div>
      </div>
      <div className="bg"></div>
    </div>
  );
};

export const AniBubbles = ({ data, dataNoti }) => {
  // All bubble animations are defined in one sequance but have different controls
  const bubbles = async () => {
    // STATE 2: p1 appears
    await elControls0.start({
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        delay: startDelay,
        duration: baseDur,
      },
    });
    // STATE 3: p1 is talking, p2 comes in
    bgControls0.start({
      scale: [1, 1.2, 1.3, 1.2, 1.1, 1.2, 1],
      transition: {
        delay: startDelay,
        duration: baseDur * 3,
      },
    });
    elControls1.start({
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        delay: startDelay,
        duration: baseDur,
      },
    });
    await bgControls1.start({
      scale: [1.2, 1.3, 1.2, 1.1, 1.2, 1],
      transition: {
        delay: startDelay,
        duration: baseDur * 3,
      },
    });
    // STATE 4: p3 comes in and starts talking
    elControls2.start({
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        delay: startDelay - baseDur,
        duration: baseDur,
      },
    });
    bgControls2.start({
      scale: [1.2, 1.1, 1.2, 1.3, 1.2, 1],
      opacity: [0, 1],
      transition: {
        delay: startDelay,
        duration: baseDur * 3,
      },
    });
    await bgControls0.start({
      scale: [1, 1.2, 1.1, 1.1, 1.2, 1.3, 1],
      transition: {
        delay: startDelay,
        duration: baseDur * 3,
      },
    });
    // STATE 5: p4 appears, p1 is talking
    elControls3.start({
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        delay: startDelay - baseDur * 3,
        duration: baseDur,
      },
    });
    await bgControls0.start({
      scale: [1.2, 1.3, 1.2, 1.1, 1.2, 1.3, 1],
      transition: {
        delay: startDelay,
        duration: baseDur * 3,
      },
    });
    // STATE 6: p5 appears, p2 is talking, p4 is talking
    elControls4.start({
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        delay: 1.2,
        duration: baseDur,
      },
    });
    bgControls1.start({
      scale: [1, 1.2, 1.3, 1.2, 1.3, 1.2, 1],
      transition: {
        delay: startDelay,
        duration: baseDur * 3,
      },
    });
    await bgControls3.start({
      scale: [1, 1.2, 1, 1.1, 1, 1.2, 1],
      transition: {
        delay: startDelay,
        duration: baseDur * 3,
      },
    });
    // STATE 7:  p6 appears, p2 is talking, p4 is talking
    elControls5.start({
      scale: [0, 1],
      opacity: [0, 1],
      transition: {
        delay: 1,
        duration: baseDur,
      },
    });
    bgControls4.start({
      scale: [1, 1.1, 1.2, 1, 1.2, 1.1, 1],
      transition: {
        delay: startDelay,
        duration: baseDur * 3,
      },
    });
    return await bgControls0.start({
      scale: [1, 1.2, 1.1, 1.1, 1.2, 1.3, 1],
      transition: {
        // delay: startDelay - baseDur * 3,
        duration: baseDur * 3,
      },
    });
  };

  const bubblesFinal = async (dataFirst, dataSecond) => {
    let bubbleId1 = dataFirst.el;
    let bubbleId2 = dataSecond.el;

    let bgControl1 = eval(`bgControls${bubbleId1}`);
    let bgControl2 = eval(`bgControls${bubbleId2}`);
    // console.log("bubbleId2", bubbleId2, "bubbleId1", bubbleId1);

    await bgControl1.start({
      scale: [1, 1.2, 1, 1.1, 1, 1.2, 1],
      transition: {
        delay: startDelay,
        duration: baseDur * 3,
      },
    });
    return await bgControl2.start({
      scale: [1, 1.2, 1, 1.1, 1, 1.2, 1],
      transition: {
        delay: startDelay,
        duration: baseDur * 3,
      },
    });
  };

  // declare animation sequences for whole element, background and icon
  const elControls0 = useAnimation();
  const bgControls0 = useAnimation();
  const iconControls0 = useAnimation();

  const elControls1 = useAnimation();
  const bgControls1 = useAnimation();
  const iconControls1 = useAnimation();

  const elControls2 = useAnimation();
  const bgControls2 = useAnimation();
  const iconControls2 = useAnimation();

  const elControls3 = useAnimation();
  const bgControls3 = useAnimation();
  const iconControls3 = useAnimation();

  const elControls4 = useAnimation();
  const bgControls4 = useAnimation();
  const iconControls4 = useAnimation();

  const elControls5 = useAnimation();
  const bgControls5 = useAnimation();
  const iconControls5 = useAnimation();

  // Set fixed sequence for animations to play
  const sequenceAnimation = async (dataLastTwo) => {
    await bubbles();

    await bubblesFinal(dataLastTwo.notiLoop1, dataLastTwo.notiLoop2);
  };

  sequenceAnimation(dataNoti);
  // bubbles(0);

  return (
    <div className="animation--wrapper__bubbles">
      <div className="bubbles">
        <motion.div
          animate={elControls0}
          data-order={data.bub0.el}
          className="bubble--wrapper"
        >
          <Bubble
            {...data.bub0}
            animateBg={bgControls0}
            animateIcon={iconControls0}
          />
        </motion.div>
        <motion.div
          animate={elControls1}
          data-order={data.bub1.el}
          className="bubble--wrapper"
        >
          <Bubble
            {...data.bub1}
            animateBg={bgControls1}
            animateIcon={iconControls1}
          />
        </motion.div>
        <motion.div
          animate={elControls2}
          data-order={data.bub2.el}
          className="bubble--wrapper"
        >
          <Bubble
            {...data.bub2}
            animateBg={bgControls2}
            animateIcon={iconControls2}
          />
        </motion.div>
        <motion.div
          animate={elControls3}
          data-order={data.bub3.el}
          className="bubble--wrapper"
        >
          <Bubble
            {...data.bub3}
            animateBg={bgControls3}
            animateIcon={iconControls3}
          />
        </motion.div>
        <motion.div
          animate={elControls4}
          data-order={data.bub4.el}
          className="bubble--wrapper"
        >
          <Bubble
            {...data.bub4}
            animateBg={bgControls4}
            animateIcon={iconControls4}
          />
        </motion.div>
        <motion.div
          animate={elControls5}
          data-order={data.bub5.el}
          className="bubble--wrapper"
        >
          <Bubble
            {...data.bub5}
            animateBg={bgControls5}
            animateIcon={iconControls5}
          />
        </motion.div>
      </div>
      <div className="bg"></div>
    </div>
  );
};

export default AniNotification;

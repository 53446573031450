import React from "react";
import { Link } from "gatsby";
import "./styles.scss";

const Button = ({ text, to, inverted, internal }) => {
  return (
    <>
      {internal ? (
        <Link className={inverted ? "button inverted" : "button"} to={to}>
          {text}
        </Link>
      ) : (
        <a
          className={inverted ? "button inverted" : "button"}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>{text}</span>
        </a>
      )}
    </>
  );
};

export default Button;

import React from "react";
import logoSmall from "../../../assets/images/icon-test.png";
import { motion } from "framer-motion";
import mutedIcon from "../../../assets/images/icon-muted.png";
import "./styles.scss";

const Bubble = ({ name, color, icon, img, el, animateBg, animateIcon }) => {
  // let chosenIcon;

  // function setIcon(i) {
  //   // not super dynamic, but in this setup is fine
  //   if (i === "muted") {
  //     console.log("ret");
  //     return (chosenIcon = mutedIcon);
  //   } else if (i === "none" || i === " ") {
  //     return (chosenIcon = " ");
  //   } else {
  //     return chosenIcon;
  //   }
  // }
  // setIcon(icon);

  return (
    <div className="bubble--el">
      <div className="image">
        <img src={img || logoSmall} alt="Profile Icon" />
      </div>
      <motion.div
        animate={animateIcon}
        className={icon === "muted" ? "icon icon__muted" : "icon"}
      >
        {icon === "muted" ? (
          <img src={mutedIcon} alt="Icon Muted" className="image--icon" />
        ) : (
          <span role="img" className="emoji--icon" aria-label="emoji">
            {icon || "🤠"}
          </span>
        )}
      </motion.div>

      <motion.div
        animate={animateBg}
        className="circle"
        style={{ backgroundColor: color }}
      />
      <h3 className="name">{name}</h3>
    </div>
  );
};

export default Bubble;
